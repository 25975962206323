<template>
  <section class="bando-head">
    <h1>Mentions légales</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">1 - Editeur</h2>

      <p>
        <strong>Le site </strong>
        <a href="https://rdv-fse.mesmedicamentschezmoi.com/">
          rdv-fse.mesmedicamentschezmoi.com
        </a>
        (ci-après «le Site») <strong>est édité par :</strong><br />
        La Poste<br />
        Société anonyme au capital de 5.364.851.364 €<br />
        356 000 000 RCS PARIS<br />
        Siège social : 9 rue du Colonel Pierre Avia 75757 PARIS CEDEX 15<br />
        Tél : 01 55 44 00 00<br />
        Email : contact@mesmedicamentschezmoi.com<br />
        Numéro de TVA Intracommunautaire : FR39356000000
      </p>

      <p>
        <strong>Directeur de la publication :</strong><br />
        Delphine Mallet<br />
        Directrice BU Silver et Santé<br />
        BRANCHE SERVICE COURRIER COLIS<br />
        DIRECTION DES SERVICES DE LA SILVER ECONOMIE<br />
        CP Y801<br />
        9 RUE DU COLONEL PIERRE AVIA<br />
        75757 PARIS CEDEX 15
      </p>

      <h2 class="title">2 - Hébergement</h2>

      <p>
        <strong>Données de santé (HDS) et application de santé :</strong><br />
        Coreye – Groupe Pictime<br />
        N° RCS de Lille 443 498 571<br />
        Capital social 164 402 euros<br />
        Siège Social : Campus du Digital Parc de la Haute Borne 61 avenue de
        l’Harmonie- 59262 Sainghin en Mélantois - France<br />
        Tél. : 03 28 520 520<br />
        Fax : 03 28 52 05 29<br />
        Email : accueil@pictime.com
      </p>

      <p>
        <strong>Données (hors données de santé) et application :</strong><br />
        WEEBOX<br />
        RCS : Paris 484 310 289<br />
        Siège social : 142 rue de Rivoli 75001 Paris<br />
        Tél. : +33 1 84 23 27 69<br />
        Email :
        <a class="obf_contact_email" href="mailto:contact@weebox.com">
          contact@weebox.com
        </a>
      </p>

      <h2 class="title">3. Propriété Intellectuelle</h2>

      <p>
        Le Site peut contenir des informations confidentielles ainsi que des
        données protégées par le droit de la propriété intellectuelle.
      </p>

      <p>
        Le Site est la propriété exclusive de La Poste. Toute reproduction,
        représentation, adaptation, traduction, et/ou transformation partielle
        ou intégrale de ce site ou de tout élément le composant, par quelque
        procédé que ce soit, sans l'autorisation expresse de La Poste est
        interdite et constituerait une contrefaçon sanctionnée par le Code de la
        propriété intellectuelle et engageant la responsabilité civile et pénale
        de son auteur.
      </p>

      <p>
        Les marques de La Poste, ainsi que les logos figurant sur le site sont
        des marques (semi-figuratives ou non) sont déposées et protégées par le
        droit de la propriété intellectuelle. Toute reproduction totale ou
        partielle de ces marques ou de ces logos, effectuées à partir des
        éléments du site sans l'autorisation expresse de La Poste est prohibée
        par les dispositions [au sens des articles L.713-2 et suivants] du Code
        de la propriété intellectuelle
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "MentionsLegales",
  components: {}
};
</script>

<style scoped lang="scss">
.content {
  padding: 0 5% 10rem;

  @media screen and (min-width: 992px) {
    padding: 0 10% 10rem;
  }

  .title {
    margin: 4rem 0 3rem;
  }

  p {
    @media screen and (min-width: 992px) {
      font-size: 1.8rem;
    }
  }
}
</style>
